export type ImagesSizes = 'hero' | 'left' | 'right' | 'bottom'

export default function useImages() {
  const getImagePrefix = (image: string) => {

    const runtimeConfig = useRuntimeConfig();

    if (process.server && runtimeConfig.public.environmentName === 'development' && image.indexOf('http') === -1) {
      if (image.indexOf('siteassets') !== -1 || (image.indexOf('globalassets') !== -1)) {
        return `http://localhost:8084${image}`;
      }
    }
    
    return image;
  };
  const getImageWidth = (placement: ImagesSizes = 'bottom') => {
    switch (placement) {
      case 'hero':
        return 1372;
      case 'left':
        return 636;
      case 'right':
        return 330;
      default:
        return 1260;
    }
  };
  const getSizesAttr = (placement: ImagesSizes = 'bottom') => {
    return `sm:100vw md:${getImageWidth(placement)}px`;
  };

  return {
    getImagePrefix,
    getSizesAttr,
    getImageWidth,
  };
}
